import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Packages
import { Placeholder } from 'react-bootstrap';
import Axios from 'axios';
import { Buffer } from 'buffer';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

// Components
import SelectButton from '../utils/SelectButton';
import Canvas from './Canvas';
import TagList from './TagList';
import TagsChart from './TagsChart';
import Alert from '../utils/Alert';
import TicketModal from '../tickets/TicketModal';
import ImageTickets from '../tickets/ImageTickets';
import Loading from '../utils/Loading';
import PictureButtons from './PictureButtons';
import ReconTable from './ReconTable';

// Hooks
import useDidMountEffect from '../../hooks/useDidMountEffect';

// Actions
import { get_payload_info, set_payload_images } from '../../actions/payloadActions';
import { get_image_info, get_image_result, get_image_tags } from '../../actions/imageActions';
import { get_result_info, get_result_tags } from '../../actions/resultActions';
import { ticket_errors_clear } from '../../actions/ticketActions';
import { get_all_projects } from '../../actions/projectsActions';

// Utils
import formatString from '../../utils/formatString';
import isEmpty from '../../utils/isEmpty';
import { initElementsTagsList } from '../../utils/initTagsList';

const Display = () => {
	const { t } = useTranslation();

	const { location_id, payload_id, image_id } = useParams();

	const dispatch = useDispatch();

	const { user } = useSelector(state => state.auth);

	const { app_config } = useSelector(state => state.organization);
	
	const { payload_info, payload_images } = useSelector(state => state.payload);

	const { image_loading, image_info, image_result_loading, image_result, image_tags_loading, image_tags } = useSelector(state => state.image);
	const image_error = useSelector(state => state.image.error);

	const { result_info, result_tags_loading, result_tags, error } = useSelector(state => state.result);

	const { all_projects } = useSelector(state => state.projects);

	const defaultHeight = 700;

	const [appOrgConfig, setAppOrgConfig] = useState({});

	const [image, setImage] = useState('');
	const [imageLoading, setImageLoading] = useState(true);
	const [imageError, setImageError] = useState({ isError: false, msg: '' });

	const [globalTagsOpts, setGlobalTagsOpts] = useState([]);
	const [resourceTagsOpts, setResourceTagsOpts] = useState([]);
	const [resultTypeOpts, setResultTypeOpts] = useState([]);

	const [resultId, setResultId] = useState('');
	const [selectedResult, setSelectedResult] = useState({});

	const [recon, setRecon] = useState('');
	const [canvas, setCanvas] = useState(null);
	const [tagsList, setTagsList] = useState([]);
	const [predictions, setPredictions] = useState([]);
	const [photoIndex, setPhotoIndex] = useState(-1);

	const [width, setWidth]   = useState(window.innerWidth);
	const [height, setHeight] = useState(window.innerHeight);

	const updateDimensions = () => {
		setWidth(window.innerWidth);
		setHeight(window.innerHeight);
	}

	useEffect(() => {
		Axios.get(`${process.env.REACT_APP_SERVER_URL}/api/images/${image_id}/data`,{
			responseType: 'arraybuffer'
		})
		.then((res) => {
			let data = `data:${res.headers['content-type']};base64, ${Buffer.from(res.data, 'binary').toString('base64')}`;
			setImage(data);
			setImageLoading(false);
		}).catch((err) => {
			setImageError({ isError: true, msg: err.message });
			setImageLoading(false);
		});

		if (payload_id) {
			dispatch(get_payload_info(payload_id));
		}
		dispatch(get_image_info(image_id));
		dispatch(get_image_result(image_id));
		dispatch(get_image_tags(user.organization));

		window.addEventListener('resize', updateDimensions);
		return () => window.removeEventListener('resize', updateDimensions);
	}, []);

	useEffect(() => {
		dispatch(set_payload_images(payload_info.data));
	}, [payload_info]);

	useEffect(() => {
		setGlobalTagsOpts([]);
		setResourceTagsOpts([]);
		// set global & resource tags from org
		if (!isEmpty(image_info) && !isEmpty(image_tags)) {
			image_tags.global.forEach((global_tag) => {
				if ((image_info.tags & global_tag.value) === global_tag.value) {
					setGlobalTagsOpts(prevArray => [...prevArray, global_tag.name]);
				}
			});

			image_tags.resource.forEach((resource_tag) => {
				if ((image_info.org_tags & resource_tag.value) === resource_tag.value) {
					setResourceTagsOpts(prevArray => [...prevArray, resource_tag.name]);
				}
			});
			
			const appOrgConfig = app_config.find((config) => config.organization.$oid === image_info.organization.$oid);
			setAppOrgConfig(appOrgConfig);
		}
	}, [image_info, image_tags, app_config]);

	useEffect(() => {
		setResultTypeOpts([]);
		// Create result types options for select button
		if (!isEmpty(image_result)) {
			image_result.results.forEach((img_result, idx) => {
				let option_name = '';
				if (img_result.options) {
					option_name = formatString(img_result?.options?.name.replace(/\./g, ' '));
				}
				else {
					option_name = formatString(img_result.r_type.name.replace(/\./g, ' '))
				}

				let option = {
					_id: img_result.result,
					name: option_name,
					r_type_id: img_result.r_type._id.$oid,
					type: img_result.r_type.result_type,
					result_options: img_result?.options?._id?.$oid || null
				};
				setResultTypeOpts(prevArray => [...prevArray, option]);
			})
		}
	}, [image_result]);

	const onChangeResultType = (e, name) => {
		if (e !== null) {
			setRecon('');
			setResultId(e.value);
		}
	}
	
	useEffect(() => {
		if (resultId !== '') {
			setTagsList([]);

			const resultFound = resultTypeOpts.find(result => result._id.$oid === resultId);
			setSelectedResult(resultFound);
			
			const projectFilters = {
				organization: image_info?.organization?.$oid,
				result: resultFound?.r_type_id,
				...(resultFound?.result_options !== null && { options: resultFound?.result_options }),
			}
			dispatch(get_all_projects(projectFilters));

			dispatch(get_result_info(resultFound.r_type_id, resultId));
		}
	}, [resultId]);

	useEffect(() => {
		// Get result tags from selected result info
		if (!isEmpty(selectedResult)) {
			if (selectedResult.result_options) {
				dispatch(get_result_tags(true, selectedResult.result_options));
			}
			else if (selectedResult.r_type_id) {
				dispatch(get_result_tags(false, selectedResult.r_type_id));
			}
		}
	}, [selectedResult]);

	useEffect(() => {
		if (selectedResult.type === 1) {
			setRecon('predictions');
		}
		else if (selectedResult.type === 3) {
			setRecon('elements');
		}
	}, [result_info]);

	useDidMountEffect(() => {
		if (!result_tags_loading) {
			if (recon === 'elements') {
				setPredictions(result_info.elements);
				setTagsList(initElementsTagsList(result_info.elements, result_tags));
			}
			else if (recon === 'predictions') {
				setPredictions(result_info.predictions);
				setTagsList(initElementsTagsList(result_info.predictions, result_tags));
			}
			else if (recon === 'extras') {
				setPredictions(result_info.extras);
				setTagsList(initElementsTagsList(result_info.extras, result_tags));
			}
		}
	}, [recon, result_tags]);

	const selectPhoto = (e, idx) => {
		e.preventDefault();

		let index = photoIndex + idx;
		window.location = `/locations/${location_id}/payloads/${payload_id}/image/${payload_images[index]['imageId']}`;
	}

	useEffect(() => {
		const index = payload_images.findIndex(object => {
			return object.imageId === image_id;
		});
		setPhotoIndex(index);
	}, [payload_images]);

	if (image_loading || imageLoading) {
		return (
			<Loading msg={t('display.loading')} />
		);
	}
	else {
		return ( 
			<div id={localStorage.getItem('theme')}>
				<TicketModal width={width} imageId={image_id} result={selectedResult} />

				{payload_images.length > 0 &&
					<nav className='navbar navbar-expand-lg'>
						<div className='container-fluid'>
							<a href={`/locations/${location_id}/payloads/${payload_id}`} className='nav-link text-white button-back'>
								<i className='bi bi-arrow-left-square'></i> {t('display.returnPayload')}
							</a>
						</div>
					</nav>
				}
				<div className='container-fluid container-padding my-3'>
					<div className='container'>
						{/* Datos de la imagen */}
						<p className='text-center fs-3'>{t('display.recon')}</p>
						<hr/>
						<p className='text-center fs-4 my-0'>
							<i className='bi bi-file-image ms-1 me-2'></i>{t('display.imgInfo')} <ImageTickets imageId={image_id}/>
						</p>
						<p className='form-label text-center'><b>Id:</b> {image_id}</p>
						{payload_images.length > 0 &&
							<p className='form-label text-center'>
								<b>{t('display.imgType')}</b> {formatString(payload_images[photoIndex]?.type)}
							</p>
						}
						<p className='form-label text-center'><b>{t('display.imgName')}</b> {image_info.filename}</p>
						<hr/>
						
						{image_tags_loading
							? <div className='text-center'>
									<Placeholder className='form-label' as='p' animation='glow'>
										<Placeholder className='rounded-3' xs={3} />
									</Placeholder>
									<Placeholder className='form-label' as='p' animation='glow'>
										<Placeholder className='rounded-3' xs={3} />
									</Placeholder>
								</div>
							:	<div>
									{globalTagsOpts.length > 0 &&
										<p className='form-label text-center'>
											<b>{t('display.globalTags')}</b>
											{globalTagsOpts.map((globalTag, idx) => ( globalTag + (idx === (globalTagsOpts.length - 1) ? '' : ', ') ))}
										</p>
									}
			
									{resourceTagsOpts.length > 0 &&
										<p className='form-label text-center'>
											<b>{t('display.resourceTags')}</b>
											{resourceTagsOpts.map((resourceTag, idx) => ( resourceTag + (idx === (resourceTagsOpts.length - 1) ? '' : ', ') ))}
										</p>
									}
								</div>
						}
						
						<div className='d-flex row justify-content-center align-items-center my-4'>
							{image_result_loading
								?	<Placeholder className='form-label text-center' as='p' animation='glow'>
										<Placeholder className='rounded-3' style={{ height: '38px' }} xs={5} />
									</Placeholder>
								:	!image_error.isError && image_error.type !== 'image_result'
									? <div className='d-flex row justify-content-center align-items-center'>
											<div className='col-lg-2 col-md-3 col-sm-12 text-center'>
												<label htmlFor='staticEmail2'>{t('display.resultTypes')}</label>
											</div>
											<div className='col-lg-3 col-md-5 col-sm-12'>
												<SelectButton
													options={resultTypeOpts.length !== 0 ? resultTypeOpts : []}
													name='resultType'
													value={resultId}
													onChange={onChangeResultType}
													loading={resultTypeOpts.length === 0}
												/>
											</div>
										</div>
									: <Alert type='info' msg={t('display.alert')}/>
							}
						</div>

						{selectedResult.type === 1 &&
							<div className='d-flex justify-content-around m-2'>
								{result_info.n_elements > 0 &&
									<div className='form-check form-check-inline'>
										<input className='form-check-input' type='radio' id='elements' 
											name='elements' value='elements' onChange={(e) => setRecon(e.target.value)}
											checked={recon === 'elements'}
										/>
										<label className='form-check-label' htmlFor='elements'>{t('display.resultInfo.elements')}</label>
									</div> 
								}
								{result_info.n_predictions > 0 &&
									<div className='form-check form-check-inline'>
										<input className='form-check-input' type='radio' id='predictions' 
											name='predictions' value='predictions' onChange={(e) => setRecon(e.target.value)}
											checked={recon === 'predictions'}
										/>
										<label className='form-check-label' htmlFor='predictions'>{t('display.resultInfo.predictions')}</label>
									</div>
								}
								{result_info.n_extras > 0 &&
									<div className='form-check form-check-inline'>
										<input className='form-check-input' type='radio' id='extras' 
											name='extras' value='extras' onChange={(e) => setRecon(e.target.value)}
											checked={recon === 'extras'}
										/>
										<label className='form-check-label' htmlFor='extras'>{t('display.resultInfo.extras')}</label>
									</div>
								}	
							</div>
						}
					</div>

					{(width < 800 && payload_images.length > 0) &&
						<div>
							<button type='button' className='btn btn-secondary'
								disabled={photoIndex === 0 ? true : false} onClick={(e) => selectPhoto(e, -1)}
							>
								<i className='bi bi-chevron-left me-2'></i> {t('display.photosBtns.previous')}
							</button>
							<button type='button' className='btn btn-secondary float-end'
								disabled={photoIndex === payload_images.length -1 ? true : false} onClick={(e) => selectPhoto(e, 1)}
							>
								{t('display.photosBtns.next')} <i className='bi bi-chevron-right ms-2'></i> 
							</button>
						</div>
					}

					<div className='d-flex justify-content-around align-items-center'>
						{(width > 800 && payload_images.length > 0) &&
							<div className='col-lg-1 col-md-1 col-sm-1'>
								<button className='btn btn-outline-secondary rounded-circle fs-4' title={t('display.photosBtns.previous')}
									disabled={photoIndex === 0 ? true : false} onClick={(e) => selectPhoto(e, -1)}
								>
									<i className='bi bi-chevron-left'></i>
								</button>
							</div>
						}
						<div className='container d-flex row justify-content-center'>
							<div className='col-sm-12 col-md-12 col-lg-6 my-2'>
								<div className='d-flex row'>
									<PictureButtons
										imageInfo={image_info}
										imageSrc={image}
										width={width}
									/>
									<div className='col-sm-12 col-md-12 col-lg-10 col-xl-10'>
										<div className={clsx('card', {
												'border-dark text-bg-dark': localStorage.getItem('theme') === 'dark'
											})}
											style={{ height: defaultHeight }}
										>
											{imageError.isError
												?	<div className='d-flex justify-content-center align-items-center' style={{ height: defaultHeight }}>
														<div className='text-center'>
															<i className='bi bi-exclamation-circle-fill fs-1 text-danger'></i>
															<p className='fs-2 my-0'>{t('display.errors.image')}</p>
															<p>{imageError.msg}</p>
														</div>
													</div>
												:	<Canvas
														id={1}
														height={defaultHeight}
														urlPhoto={image}
														canvas={canvas}
														setCanvas={setCanvas}
														tagsList={tagsList}
														predictions={predictions}
													/>
											}
										</div>
									</div>
								</div>
							</div>
							{(!image_error.isError && image_error.type !== 'image_result') &&
								<div className='col-sm-12 col-md-12 col-lg-6 mt-2 mb-2 custom-scrollbar'>
									<div className={clsx('card', {
											'border-dark text-bg-dark': localStorage.getItem('theme') === 'dark'
										})}
										style={{ height: selectedResult.type === 2 ? 170 : defaultHeight, overflowY: 'scroll' }}
									>
										{(selectedResult.type === 1 || selectedResult.type === 3) && // RESULT_TYPE_ELEMENTS || RESULT_TYPE_PRICES
											<TagList
												canvas={canvas}
												resultTagsLoading={result_tags_loading}
												appOrgConfig={appOrgConfig}
												tagsList={tagsList}
												selectedResult={selectedResult}
												imageInfo={image_info}
											/>
										}
									</div>
								</div>
							}
						</div>
						{(width > 800 && payload_images.length > 0) &&
							<div className='col-sm-1 col-md-1 col-lg-1'>
								<button className='btn btn-outline-secondary rounded-circle fs-4 float-end' title={t('display.photosBtns.next')}
									disabled={photoIndex === payload_images.length -1 ? true : false} onClick={(e) => selectPhoto(e, 1)}
								>
									<i className='bi bi-chevron-right'></i>
								</button>
							</div>
						}
					</div>
					
					<div className='container'>
						<div className='d-flex row justify-content-center'>
							<div className='d-grid gap-2 p-1 col-sm-12 col-md-6 col-lg-6'>
								<button type='button' className='btn simple-btn' onClick={() => { canvas?.setFitZoom() }}>
									<i className='bi bi-aspect-ratio-fill ms-1 me-2'></i> {t('display.actionBtns.fitImg')}
								</button>
							</div>
							<div className='d-grid gap-2 p-1 col-sm-12 col-md-6 col-lg-6'>
								<button type='button' className='btn danger-btn' data-bs-toggle='modal' data-bs-target='#createTicket'
									onClick={() => dispatch(ticket_errors_clear())} disabled={!all_projects.length > 0 || isEmpty(selectedResult)}
								>
									<i className='bi bi-file-excel-fill ms-1 me-2'></i> {t('display.actionBtns.createTicket')}
								</button>
							</div>
						</div>
						<hr/>

						{result_info.custom && <ReconTable resultInfo={result_info} /> }

						<p className='text-center fs-4 my-0'><i className='bi bi-pie-chart ms-1 me-2'></i>{t('display.productsGraph')}</p>
						<TagsChart
							appOrgConfig={appOrgConfig}
							tagsList={tagsList}
							selectedResult={selectedResult}
							imageInfo={image_info}
						/>
					</div>
				</div>
			</div>
		);
	}
}
 
export default Display;
