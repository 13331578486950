export const SPANISH_TRANSLATIONS = {
  // MAIN COMPONENTS
  navbar: {
    options: {
      search: 'Consultar',
      payloads: 'Payloads',
      issues: 'Incidencias',
			images: 'Imágenes',
			frauds: 'Falseos'
    },
    menu: {
      account: 'Cuenta',
      return: 'Regresar al menú',
      configuration: 'Configuración',
      logOut: 'Cerrar sesión'
    },
  },
  landing: {
    description: 'Esta herramienta nos permite consultar la información de un payload visualizando e interactuando con las fotografías pudiendo observar los productos que han sido reconocidos por el motor de imágenes.'
  },
  configuration: {
    title: 'Configuraciones',
    options: {
      appearance: 'Apariencia'
    },
    appearance: {
      themes: 'Temas',
      light: 'Claro',
      dark: 'Oscuro'
    }
  },

  // SEARCH COMPONENTS
  search: {
    title: 'Consultar Payloads',
    errorAlertMsg: 'Faltan campos por completar!',
		errorLocationAlertMsg: 'No se ha seleccionado ningún establecimiento!',
    filters: {
      organization: 'Organización',
      orgLegend: 'Selecciona una organización.',
      workspace: 'Workspace',
      workspaceLegend: 'Selecciona un workspace.',
      module: 'Módulo',
      moduleLegend: 'Selecciona un módulo.',
      searchFor: 'Buscar por:',
      searchForLegend: 'Selecciona una opción.',
      searchForOptions: {
        name: 'Nombre del establecimiento',
        code: 'Código del establecimiento',
        payloadRef: 'Referencia de payload',
        issueRef: 'Referencia de incidencia'
      },
      cycle: 'Ciclo',
      start: 'Inicio:',
      end: 'Fin:',
      cycleLegend: 'Selecciona un ciclo.',
    },
    nameOption: {
      alert: {
        msg: 'Establecimiento seleccionado!',
        name: ' Nombre:',
        code: 'Código:'
      },
      location: 'Nombre del establecimiento',
      locationLegend: 'Escribe el nombre del establecimiento.',
    },
    codeOption: {
      errorAlert: {
        msg: 'Establecimiento no encontrado!'
      },
      alert: {
        msg: 'Establecimiento encontrado!',
        name: 'Nombre:',
        code: ' Código:'
      },
      location: 'Código del establecimiento',
      locationLegend: 'Escribe el código del establecimiento.',
    },
    payloadRefOption: {
      errorAlert: {
        msg: 'Payload no encontrado!'
      },
      alert: {
        msg: 'Payload encontrado!'
      },
      input: 'Referencia del payload',
      inputLegend: 'Escribe la referencia del payload.',
    },
    issueRefOption: {
      errorAlert: {
        msg: 'Incidencia no encontrada!'
      },
      alert: {
        msg: 'Incidencia encontrada!'
      },
      input: 'Referencia de incidencia',
      inputLegend: 'Escribe la referencia de incidencia.',
    },
    imageOption: {
      errorAlert: {
        msg: 'Imagen no encontrada!'
      },
      alert: {
        msg: 'Imagen encontrada!'
      },
			title: 'Consultar Imágenes',
      input: 'Referencia de la Imagen',
      inputLegend: 'Escribe la referencia de la Imagen.',
			imgRecon: 'Reconocimiento',
			imgType: 'Tipo',
			imgGroup: 'Grupo',
			goToImage: 'Ir a la imagen'
    },
    searchBtn: 'Buscar'
  },
  locationsTable: {
    headers: {
      name: 'Nombre',
      code: 'Código',
      status: 'Estado'
    },
    errors: {
      noCode: 'Sin código',
      noLocations: 'No se encontraron establecimientos'
    }
  },
  locationsRecords: {
    headers: {
      type: 'Tipo',
      date: 'Fecha',
      action: 'Acción'
    },
    actions: {
      goToPayload: 'Ir al payload',
      goToIssue: 'Ir a issue'
    },
    errors: {
      noRecords: 'No hay records'
    },
    types: {
      payload: 'Payload',
      issue: 'Incidencia',
      other: 'Otro'
    }
  },

  // ALL PAYLOADS COMPONENTS
  allPayloads: {
    title: 'Consultar Payloads',
    errorAlertMsg: 'Faltan campos por completar!',
    filters: {
      organization: 'Organización',
      orgLegend: 'Selecciona una organización.',
      workspace: 'Workspace',
      workspaceLegend: 'Selecciona un workspace.',
      module: 'Módulo',
      moduleLegend: 'Selecciona un módulo.',
      submodule: 'Submódulo',
      submoduleLegend: 'Selecciona un submódulo.',
      cycle: 'Ciclo',
      cycleLegend: 'Selecciona un ciclo.',
      datePicker: 'Fecha',
      datePickerLegend: 'Selecciona una fecha o un rango de fechas.',
      areas: 'Áreas',
      areasLegend: 'Selecciona las áreas.',
      areasOptions: {
        region: 'Región',
        zone: 'Zona',
        depot: 'Depot',
        group: 'Grupo',
        route: 'Ruta'
      }
    },
    searchBtn: 'Buscar'
  },
  payloadsTable: {
    headers: {
      code: 'Código',
      date: 'Fecha',
      status: 'Estado',
      action: 'Acción'
    },
    actions: {
      goToPayload: 'Ir al payload'
    },
    errors: {
      noCode: 'Sin código',
      noPayloads: 'No hay payloads'
    }
  },

  // PAYLOAD COMPONENTS
  payload: {
    title: 'Datos Generales',
    loading: 'Cargando información...',
    locationInfo: {
      title: 'Información del Establecimiento',
      code: 'Código',
      name: 'Nombre',
      description: 'Descripción',
      status: 'Estatus',
      lat: 'Latitud',
      lng: 'Longitud',
      creationDate: 'Fecha de creación',
      referenceImg: 'Imagen de referencia'
    },
    payloadInfo: {
      title: 'Información de Payload',
			processType: {
				online: 'Payload enviado en modo online.',
				offline: 'Payload enviado en modo offline.'
			},
      workspace: 'Workspace',
      module: 'Módulo',
      submodule: 'Submódulo',
      config: 'Configuración',
      user: 'Usuario',
      cycle: 'Ciclo',
      creationDate: 'Fecha de creación',
      region: 'Región',
      zone: 'Zona',
      depot: 'Depot',
      group: 'Grupo',
      route: 'Ruta',
      error: 'Sin Información'
    },
    payloadStatus: {
      title: 'Estado de Payload',
      status: 'Estado',
      statusDate: 'Fecha del último estado',
      historyBtn: 'Ver historial de estado'
    },
		payloadScore: {
			loading: 'Cargando calificacion...',
      title: 'Calificación de Payload',
      score: 'Calificación Total',
			error: 'Este payload no tiene calificacion.'
    },
    payloadContent: {
      title: 'Contenido de Payload',
      tableHeaders: {
        type: 'Tipo',
        info: 'Información',
        images: 'Imágenes'
      },
      errors: {
        noContent: 'Sin Información'
      }
    }
  },
  statusModal: {
    title: 'Historial de Estado',
    tableHeaders: {
      prevStatus: 'Estado previo',
      currentStatus: 'Estado actual',
      date: 'Fecha'
    },
    errors: {
      noHistory: 'Sin Información'
    }
  },

  // ISSUE COMPONENTS
  issue: {
    allIssues: {
      title: 'Incidencias',
      issueTypeLegend: 'Selecciona un tipo de incidencia.',
    },
    issueInfo: {
      title: 'Información de la Incidencia',
			processType: {
				online: 'Incidencia enviada en modo online.',
				offline: 'Incidencia enviada en modo offline.'
			}
    },
    issueType: {
      title: 'Tipo de la Incidencia',
      issue_tag: 'Tipo(s) de etiqueta(s) personalizada: ',
      descriptions: {
        closed: 'El establecimiento está cerrado.',
        closed_down: 'El establecimiento está cerrado permanentemente.',
        access: 'No fue permitido el acceso al interior del establecimiento.',
        process: 'No fue permitido realizar la evaluación al establecimiento.',
        products: 'El establecimiento no cuenta con productos relacionados con la organización.',
        custom: 'Incidencia personalizada.'
      }
    },
    issuesTable: {
      headers: {
        code: 'Código',
        date: 'Fecha',
        type: 'Tipo',
        action: 'Acción'
      },
      actions: {
        goToIssue: 'Ir a issue'
      },
      errors: {
        noCode: 'Sin código',
        noIssues: 'No hay incidencias'
      }
    }
  },

  // DISPLAY COMPONENTS
  display: {
    returnPayload: 'Regresar al payload',
		recon: 'Reconocimiento de Productos',
		imgInfo: 'Información de la Imagen',
		numTickets: 'Número de Tickets',
    imgType: 'Tipo:',
    imgName: 'Nombre:',
    loading: 'Cargando result...',
    globalTags: 'Etiquetas globales: ',
    resourceTags: 'Etiquetas de recursos: ',
    resultTypes: 'Tipos de result:',
    alert: 'No existen reconocimientos para esta imagen.',
    resultInfo: {
      elements: 'Elementos',
      predictions: 'Predicciones',
      extras: 'Extras'
    },
    photosBtns: {
      previous: 'Foto Anterior',
      next: 'Siguiente Foto'
    },
    errors: {
      image: 'Error al cargar la imagen'
    },
    actionBtns: {
      fullScreen: 'Imagen en pantalla completa',
      download: 'Descargar imagen',
      fitImg: 'Ajustar Imagen',
      createTicket: 'Crear Ticket'
    },
    productsGraph: 'Gráfica de Productos'
  },
  ticketModal: {
    instuction: 'Si no has seleccionado un result, selecciónalo antes de crear un ticket.',
    project: 'Proyecto',
    name: 'Nombre',
    description: 'Descripción',
    cancel: 'Cancelar',
    create: 'Crear',
    creating: 'Creando...'
  },
  tagList: {
    colorSwal: {
      title: 'Ajuste de trasparencia',
			text: 'Transparencia de color para los artículos',
    },
    tableHeaders: {
      color: 'Color',
      quantity: 'Cantidad',
      products: 'Productos',
      price: 'Precio',
      score: 'Puntaje'
    },
    errors: {
      noProducts: 'Sin productos'
    }
  },
  tagChart: {
    errors: {
      noProducts: 'Sin productos'
    }
  },

	// TICKETS COMPONENTS
	tickets: {
		filters: {
			projectLegend: 'Selecciona un proyecto.',
		},
		help: {
			title: '¿Qué proyeto debo seleccionar?',
			msg: 'Los proyectos que comienzan con "Test -" contienen tickets destinados a la realización de pruebas internas. Los demás proyectos contienen tickets que están orientados a su revisión y corrección en entorno de producción.'
		}
	},
	ticketsTable: {
		errors: {
			noTickets: 'No hay tickets'
		}
	},

	// FRAUD
	fraud: {
		allFraudsTitle: 'Consultar Falseos de Información',
		alert: {
			msg: 'Se detectó que este establecimiento tiene una posible duplicidad en alguna de sus fotografías.',
			link: 'Ver reporte'
		},
		title: 'Reporte de Falsificación de Información',
		print: 'Imprimir',
		false: 'Falsificación',
		data: {
			image: 'Imagen',
			imageDate: 'Fecha de Captura',
			coordinates: 'Coordenadas'
		},
		image: 'Similitud de imagen',
		products: 'Similitud de producto',
		validationBtn: 'Comenzar proceso de validación',
		reviewMsg: 'La información ha sido verificada por el supervisor.',
		confirm: {
			title: '¿Estás seguro de que quieres comenzar el proceso de validación?',
			msg: 'Esta acción agregará el establecimiento a tu ruta y no se puede deshacer.'
		},
		alerts: {
			success: 'El proceso ha comenzado, revisa tu ruta en la app.',
			error: 'Error, intenta de nuevo por favor'
		},
		errorPageTitle: 'Este payload no cuenta con falsificación de información.'
	},

  // UTILS COMPONENTS
  selectButton: {
    placeholder: 'Seleccionar...',
    noOptionsMessage: 'Sin opciones',
		loadingMessage: 'Cargando opciones...'
  },
  pagination: {
		rows: 'Filas por página:',
    of: 'de'
  },
	confirmDialog: {
		confirmBtn: 'Confirmar',
	},

  // STATUS DESCRIPTIONS
  statusDesc: {
    payload: {
      none: 'None',
      created: 'Creado',
      waiting: 'Esperando',
      processing: 'Procesando',
      good: 'Correcto',
      incomplete: 'Incompleto',
      bad: 'Incorrecto',
      flagged: 'Marcado',
      in_review: 'En revisión',
      passed: 'Pasado',
      removed: 'Eliminado',
      error: 'Error'
    },
    imgRecon: {
      none: 'None',
      waiting: 'Esperando',
      success: 'Correcto',
      error: 'Error',
      reviewed: 'Revisado',
      unavailable: 'No disponible'
    },
		imgType: {
			none: 'None',
			common: 'Común',
			upload: 'Carga',
			output: 'Salida',
			backup: 'Respaldo'
		},
		imgGroup: {
			none: 'None',
			organization: 'Organización',
			module: 'Módulo',
			location: 'Establecimiento',
			payload: 'Payload',
			result: 'Result',
			issue: 'Incidencia',
			post: 'Post',
			notification: 'Notificación'
		},
    location: {
      none: 'None',
      available: 'Disponible',
      disabled: 'Desactivada',
      removed: 'Eliminada'
    },
    record: {
      none: 'None',
      payload: 'Payload',
      issue: 'Incidencia',
			retry: 'Reintentar',
      other: 'Otro'
    },
    issue: {
      none: 'None',
      closed: 'Cerrado',
      closed_down: 'Cerrado permanentemente',
      access: 'Acceso',
      process: 'No se evaluó',
      products: 'No tiene productos',
      custom: 'Personalizada'
    },
    cycle: {
      none: 'None',
      created: 'Creado',
      starting: 'Empezando',
      available: 'Disponible',
      waiting: 'Esperando',
      completed: 'Completado',
      removed: 'Eliminado'
    },
		ticket: {
			none: 'None',
      created: 'Creado',
      waiting: 'Esperando',
      in_review: 'En revisión',
			good: 'Correcto',
			bad: 'Incorrecto',
			passed: 'Pasado',
			error: 'Error'
		}
  }
};
