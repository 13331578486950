import Axios from 'axios';

import {
	LOCATION_LOADING_TRUE,
	LOCATION_LOADING_FALSE,
	LOCATIONS_GET,
	LOCATIONS_ERROR,
	LOCATIONS_RESET,
	LOCATION_INFO_GET,
	LOCATION_INFO_ERROR,
	LOCATION_RECORDS_GET,
	LOCATION_RECORDS_ERROR,
	LOCATION_DATA_GET,
	LOCATION_DATA_ERROR
} from './types';

import create_query_params from '../utils/create_query_params';

export const location_loading_true = () => dispatch => {
	dispatch({
		type: LOCATION_LOADING_TRUE
	})
}

export const location_loading_false = () => dispatch => {
	dispatch({
		type: LOCATION_LOADING_FALSE
	})
}

export const get_locations = (filters) => dispatch => {
	// console.log(filters)
	let url = process.env.REACT_APP_SERVER_URL + '/api/locations/search?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		if (res.data.count > 0) {
			dispatch({
				type: LOCATIONS_GET,
				payload: res.data
			})
		}
		else {
			dispatch({
				type: LOCATIONS_ERROR,
				payload: { type: 'locations', msg: 'Not found' }
			})
		}
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: LOCATIONS_ERROR,
			payload: { type: 'locations', msg: err.message }
		})
	})
}

export const locations_reset = () => dispatch => {
	dispatch({
		type: LOCATIONS_RESET
	})
}

export const get_location_info = (location_id, filters) => dispatch => {
	// console.log(filters)
	dispatch(location_loading_true());
	let url = process.env.REACT_APP_SERVER_URL + `/api/locations/${location_id}/info?`;
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: LOCATION_INFO_GET,
			payload: res.data
		})
		dispatch(location_loading_false());
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: LOCATION_INFO_ERROR,
			payload: { type: 'location_info', msg: err.message }
		})
		dispatch(location_loading_false());
	})
}

export const get_location_records = (location_id, filters) => dispatch => {
	// console.log(filters)
	dispatch(location_loading_true());
	let url = process.env.REACT_APP_SERVER_URL + `/api/locations/${location_id}/records?`;
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: LOCATION_RECORDS_GET,
			payload: res.data
		})
		dispatch(location_loading_false());
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: LOCATION_RECORDS_ERROR,
			payload: { type: 'location_records', msg: err.message }
		})
		dispatch(location_loading_false());
	})
}

export const get_location_data = (location_id, filters) => dispatch => {
	// dispatch(location_loading_true());
	let url = process.env.REACT_APP_SERVER_URL + `/api/locations/${location_id}/data?`;
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		dispatch({
			type: LOCATION_DATA_GET,
			payload: res.data
		})
		// dispatch(location_loading_false());
	}).catch((err) => {
		dispatch({
			type: LOCATION_DATA_ERROR,
			payload: { type: 'location_data', msg: err.message }
		})
		// dispatch(location_loading_false());
	})
}
