import Axios from 'axios';

import {
	RESULT_LOADING_TRUE,
	RESULT_LOADING_FALSE,
	RESULT_INFO_GET,
	RESULT_INFO_ERROR,
	RESULT_TAGS_LOADING,
	RESULT_TAGS_GET,
	RESULT_TAGS_ERROR
} from './types';

export const result_loading_true = () => dispatch => {
	dispatch({
		type: RESULT_LOADING_TRUE
	})
}

export const result_loading_false = () => dispatch => {
	dispatch({
		type: RESULT_LOADING_FALSE
	})
}

export const get_result_info = (r_type_id, result_id) => dispatch => {
	// console.log(r_type_id, result_id)
	dispatch(result_loading_true());
	let url = process.env.REACT_APP_SERVER_URL + `/api/results/${result_id}/info?type=${r_type_id}`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: RESULT_INFO_GET,
			payload: res.data
		})
		dispatch(result_loading_false());
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: RESULT_INFO_ERROR,
			payload: {type: 'result_info', msg: err.message}
		})
		dispatch(result_loading_false());
	})
}

export const get_result_tags = (options, oid) => dispatch => {
	dispatch({ type: RESULT_TAGS_LOADING });
	
	const route = options ? `/api/resources/options/${oid}/tags` : `/api/results/types/${oid}/tags`;
	let url = process.env.REACT_APP_SERVER_URL + route;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: RESULT_TAGS_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: RESULT_TAGS_ERROR,
			payload: {type: 'result_tags', msg: err.message}
		})
	})
}
