import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import PropTypes from 'prop-types';

// Actions
import { get_organization_info } from '../../actions/organizationActions';

// Utils
import { enfasisObjectTag } from '../../utils/fabricJsCustom';

const Tag = (props) => {
	const { tagsList, tag, canvas, visibleAllTags, resultType, imageInfo, orgHeaders } = props;

	const dispatch = useDispatch();

	const { organization_info } = useSelector(state => state.organization);

	const { image_info } = useSelector(state => state.image);
	
	const [visibleTag, setVisibleTag] = useState(true);
	const [displayTags, setdisplayTags] = useState(null);

	useEffect(() => {
		dispatch(get_organization_info(image_info?.organization?.$oid));
	}, [imageInfo]);

	useEffect(() => {
		setVisibleTag(true);
	}, [tagsList]);

	// Hidden/show PercepthorArticles inside Canvas if its Tag is this props.tag
	useEffect(() => {
		tag.isVisible = visibleTag;
		
		if (canvas?._objects){
			canvas._objects.forEach((element) => {
				if (element.type === 'percepthorArticle'){
					if (element.tag === tag){
						element.visible = tag.isVisible;
					}
				}
			});
			canvas.requestRenderAll();
		}
	}, [visibleTag]);

	// When user click on eye icon
	useEffect(() => {
		setVisibleTag(visibleAllTags);
	}, [visibleAllTags]);
	
	return (
		<tr className='align-middle text-center'>
			<th scope='row'>
				<input type='checkbox' name={'check' + tag.id} checked={visibleTag}
					onChange={() => { setVisibleTag(!visibleTag) }}
				/>
			</th>
			<td>
				<button type='button' className='btn text-center' 
					style={{background: `rgba(${tag.arrayRGBColor[0]}, ${tag.arrayRGBColor[1]}, ${tag.arrayRGBColor[2]}, 1)`}}>
				</button>
			</td>
			<td>{tag.quantity}</td>
			<td>
				<p className='m-0 p-0 text-center' style={{cursor: 'pointer'}}
					onClick={() => enfasisObjectTag(canvas, tag, 2500)}
				>
					{tag.className}
				</p>
			</td>
			{orgHeaders.map((header, index) => (
				<td key={index} className='text-center'>{tag['data'][header]}</td>
			))}
			{/* {resultType === 3 &&
				<Fragment>
					<td className='text-center'>{(tag.score * 100).toFixed(2) + '%'}</td>
					<td className='text-center'>{tag.price}</td>
				</Fragment>
			} */}
		</tr>
	);
};

Tag.propTypes = {
	tagsList: PropTypes.array.isRequired,
	tag: PropTypes.object.isRequired,
	canvas: PropTypes.any.isRequired,
	visibleAllTags: PropTypes.bool.isRequired,
	resultType: PropTypes.number.isRequired,
	imageInfo: PropTypes.object.isRequired,
	orgHeaders: PropTypes.array.isRequired
};

export default Tag;
