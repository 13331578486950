import React from 'react';

// Packages
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

// Utils
import formatString from '../../utils/formatString';

const ReconTable = (props) => {
	const { resultInfo } = props;

	return (
		<Table className='table text-center' responsive='lg'>
			<thead className='table-head'>
				<tr className='align-middle'>
					{Object.keys(resultInfo.custom).map((key, idx) => (
						<th key={idx} scope='col'>{formatString(key)}</th>
					))}
				</tr>
			</thead>
			<tbody>
				<tr>
					{Object.values(resultInfo.custom).map((value, idx) => (
						<td key={idx}>{value}</td>
					))}
				</tr>
			</tbody>
		</Table>
	);
}

ReconTable.propTypes = {
	resultInfo: PropTypes.object.isRequired
}

export default ReconTable;
