import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { useTranslation } from 'react-i18next';

// Components
import Alert from '../utils/Alert';

// Actions
import { get_image_info } from '../../actions/imageActions';

// Utils
import isEmpty from '../../utils/isEmpty';
import { getImgGroupDesc, getImgReconStatusDesc, getImgTypeDesc } from '../../utils/getStatusDesc';
import { epochToLocalDate } from '../../utils/dateConverter';

const SearchImage = () => {
	const { t } = useTranslation();

	const dispatch = useDispatch();
	
	const { image_info, error } = useSelector(state => state.image);

	const initialAlert = { display: false, type: '', msg: '' };
	const [alert, setAlert] = useState(initialAlert);

	const [imageId, setImageId] = useState('');

	const displayImageInfo = (data) => {
		return data || t('payload.payloadInfo.error');
	}

	const searchImageById = (e) => {
		e.preventDefault();

		if (imageId === '') {
			setAlert({ display: true, type: 'danger', msg: t('search.imageOption.errorAlert.msg') });
			return;
		}
		setAlert(initialAlert);

		dispatch(get_image_info(imageId));
	}

	return ( 
		<div id={localStorage.getItem('theme')}>
			<div className='container container-padding mt-3'>
				<h2 className='text-center'>{t('search.imageOption.title')}</h2>
				<form onSubmit={searchImageById}>
					{alert.display ? <Alert type={alert.type} msg={alert.msg} /> : null}

					<div>
						{error.isError &&
							<Alert type='danger' msg={t('search.imageOption.errorAlert.msg')}/> 
						}
						
						<div className='mb-3'>
							<label className='form-label' htmlFor='payloadRefInput'>
								<i className='bi bi-fingerprint ms-1 me-2'></i> {t('search.imageOption.input')}
							</label>
							<input className='form-control me-2' id='payloadRefInput' aria-describedby='payloadRefHelp'
								name={imageId} value={imageId} onChange={(e) => { setImageId(e.target.value) }}
							/>
							<div className='form-text' id='payloadRefHelp'>{t('search.imageOption.inputLegend')}</div>
						</div>
					</div>

					<div className='d-grid gap-2 col-2 mx-auto text-center mb-5'>
						<button className='btn submit-btn' type='submit'>{t('search.searchBtn')}</button>
					</div>
				</form>

				{(!isEmpty(image_info) && !error.isError)	&&
					<div className='card shadow p-3 mb-5 bg-body rounded'>
						<div className='card-body'>
							<h5 className='card-title text-center'>{t('display.imgInfo')}</h5>
							<p className='card-subtitle mb-2 text-muted text-center'><b>Id: </b>{displayImageInfo(image_info?._id?.$oid)}</p>
							<hr/>
			
							<div className='container text-center p-0'>
								<div className='row'>
									<p className='card-text m-0 p-0'><b>{t('payload.locationInfo.name')}: </b>{displayImageInfo(image_info?.filename)}</p>
									<p className='card-text text-center'><b>{t('locationsRecords.headers.date')}: </b>{epochToLocalDate(image_info?.date?.$date)}</p>
								</div>
								<hr/>
								<div className='row'>
									<div className='col'>
										<p className='card-text m-0 p-0'><b>{t('search.imageOption.imgRecon')}: </b>{getImgReconStatusDesc(image_info?.recon)}</p>
									</div>
									<div className='col'>
										<p className='card-text m-0 p-0'><b>{t('search.imageOption.imgType')}: </b>{getImgTypeDesc(image_info?.image_type)}</p>
									</div>
									<div className='col'>
										<p className='card-text m-0 p-0'><b>{t('search.imageOption.imgGroup')}: </b>{getImgGroupDesc(image_info?.group)}</p>
									</div>
								</div>
								<hr/>
								<div className='row'>
									<div className='col'>
										<p className='card-text m-0 p-0'><b>Org Tags: </b>{displayImageInfo(String(image_info?.org_tags))}</p>
									</div>
									<div className='col'>
										<p className='card-text m-0 p-0'><b>Tags: </b>{displayImageInfo(String(image_info?.tags))}</p>
									</div>
									<div className='col'>
										<p className='card-text m-0 p-0'><b>Project Tags: </b>{displayImageInfo(String(image_info?.project_tags))}</p>
									</div>
								</div>
								<hr/>
							</div>
							
							<div className='row text-end mt-4'>
								<a className='card-link text-end text-decoration-none' target='_blank'
									href={`/image/${image_info?._id?.$oid}`}
								>
									{t('search.imageOption.goToImage')} <i className='bi bi-chevron-right ms-2 me-1'></i>
								</a>
							</div>
						</div>
					</div>
				}
			</div>
		</div>
	);
}

export default SearchImage;
