export const ENGLISH_TRANSLATIONS = {
  // MAIN COMPONENTS
  navbar: {
    options: {
      search: 'Search',
      payloads: 'Payloads',
      issues: 'Issues',
			images: 'Images',
			frauds: 'Frauds'
    },
    menu: {
      account: 'Account',
      return: 'Return to menu',
      configuration: 'Configuration',
      logOut: 'Log Out'
    },
  },
  landing: {
    description: 'This tool allows us to consult the information of a payload by visualizing and interacting with the pictures, being able to see the products that have been recognized by the image engine.'
  },
  configuration: {
    title: 'Configurations',
    options: {
      appearance: 'Appearance'
    },
    appearance: {
      themes: 'Themes',
      light: 'Light',
      dark: 'Dark'
    }
  },

  // SEARCH COMPONENTS
  search: {
    title: 'Search Payloads',
    errorAlertMsg: 'Missing fields!',
    errorLocationAlertMsg: 'No location was selected!',
    filters: {
      organization: 'Organization',
      orgLegend: 'Select an organization.',
      workspace: 'Workspace',
      workspaceLegend: 'Select a workspace.',
      module: 'Module',
      moduleLegend: 'Select a module.',
      searchFor: 'Search by:',
      searchForLegend: 'Select an option',
      searchForOptions: {
        name: 'Location name',
        code: 'Location code',
        payloadRef: 'Payload reference',
        issueRef: 'Issue reference'
      },
      cycle: 'Cycle',
      start: 'Start:',
      end: 'End:',
      cycleLegend: 'Select a cycle.',
    },
    nameOption: {
      alert: {
        msg: 'Location selected!',
        name: ' Name:',
        code: 'Code:'
      },
      location: 'Location name',
      locationLegend: 'Type the location name.'
    },
    codeOption: {
      errorAlert: {
        msg: 'Location not found!'
      },
      alert: {
        msg: 'Location found!',
        name: 'Name:',
        code: ' Code:'
      },
      location: 'Location code',
      locationLegend: 'Type the location code.',
    },
    payloadRefOption: {
      errorAlert: {
        msg: 'Payload not found!'
      },
      alert: {
        msg: 'Payload found!'
      },
      input: 'Payload Reference',
      inputLegend: 'Type the payload reference.',
    },
    issueRefOption: {
      errorAlert: {
        msg: 'Issue not found!'
      },
      alert: {
        msg: 'Issue found!'
      },
      input: 'Issue Reference',
      inputLegend: 'Type the issue reference.',
    },
    imageOption: {
      errorAlert: {
        msg: 'Imagen not found!'
      },
      alert: {
        msg: 'Imagen found!'
      },
			title: 'Search Images',
      input: 'Image Reference',
      inputLegend: 'Type image reference.',
			imgRecon: 'Recognition',
			imgType: 'Type',
			imgGroup: 'Group',
			goToImage: 'Go to image'
    },
    searchBtn: 'Search'
  },
  locationsTable: {
    headers: {
      name: 'Name',
      code: 'Code',
      status: 'Status'
    },
    errors: {
      noCode: 'No code',
      noLocations: 'No locations found'
    }
  },
  locationsRecords: {
    headers: {
      type: 'Type',
      date: 'Date',
      action: 'Action'
    },
    actions: {
      goToPayload: 'Go to payload',
      goToIssue: 'Go to issue'
    },
    errors: {
      noRecords: 'No records'
    },
    types: {
      payload: 'Payload',
      issue: 'Issue',
      other: 'Other'
    }
  },

  // ALL PAYLOADS COMPONENTS
  allPayloads: {
    title: 'Search Payloads',
    errorAlertMsg: 'Missing fields!',
    filters: {
      organization: 'Organization',
      orgLegend: 'Select an organization.',
      workspace: 'Workspace',
      workspaceLegend: 'Select a workspace.',
      module: 'Module',
      moduleLegend: 'Select a module.',
      submodule: 'Submodule',
      submoduleLegend: 'Select a submodule.',
      cycle: 'Cycle',
      cycleLegend: 'Select a cycle.',
      datePicker: 'Date',
      datePickerLegend: 'Select a date or a date range.',
      areas: 'Areas',
      areasLegend: 'Select areas.',
      areasOptions: {
        region: 'Region',
        zone: 'Zone',
        depot: 'Depot',
        group: 'Group',
        route: 'Route'
      }
    },
    searchBtn: 'Search'
  },
  payloadsTable: {
    headers: {
      code: 'Code',
      date: 'Date',
      status: 'Status',
      action: 'Action'
    },
    actions: {
      goToPayload: 'Go to payload'
    },
    errors: {
      noCode: 'No code',
      noPayloads: 'No payloads'
    }
  },

  // PAYLOAD COMPONENTS
  payload: {
    title: 'General Data',
    loading: 'Loading information...',
    locationInfo: {
      title: 'Location Information',
      code: 'Code',
      name: 'Name',
      description: 'Description',
      status: 'Status',
      lat: 'Latitude',
      lng: 'Longitude',
      creationDate: 'Date of creation',
      referenceImg: 'Reference image'
    },
    payloadInfo: {
      title: 'Payload Information',
			processType: {
				online: 'Payload sent in online mode.',
				offline: 'Payload sent in offline mode.'
			},
      workspace: 'Workspace',
      module: 'Module',
      submodule: 'Submodule',
      config: 'Configuration',
      user: 'User',
      cycle: 'Cycle',
      creationDate: 'Date of creation',
      region: 'Region',
      zone: 'Zone',
      depot: 'Depot',
      group: 'Group',
      route: 'Route',
      error: 'No information'
    },
    payloadStatus: {
      title: 'Payload Status',
      status: 'Status',
      statusDate: 'Date of last status',
      historyBtn: 'View status history'
    },
		payloadScore: {
			loading: 'Loading score...',
      title: 'Payload Score',
      score: 'Total Score',
			error: 'This payload has no score.'
    },
    payloadContent: {
      title: 'Payload Content',
      tableHeaders: {
        type: 'Type',
        info: 'Information',
        images: 'Images'
      },
      errors: {
        noContent: 'No content'
      }
    }
  },
  statusModal: {
    title: 'Status History',
    tableHeaders: {
      prevStatus: 'Previous status',
      currentStatus: 'Current status',
      date: 'Date'
    },
    errors: {
      noHistory: 'No history'
    }
  },

  // ISSUE COMPONENTS
  issue: {
    allIssues: {
      title: 'Issues',
      issueTypeLegend: 'Select an issue type.',
    },
    issueInfo: {
      title: 'Issue Information',
			processType: {
				online: 'Issue sent in online mode.',
				offline: 'Issue sent in offline mode.'
			}
    },
    issueType: {
      title: 'Issue Type',
      issue_tag: 'Type(s) of custom tag(s): ',
      descriptions: {
        closed: 'The location is closed.',
        closed_down: 'The location is permanently closed.',
        access: 'Access to the location was not allowed.',
        process: 'The evaluation was not allowed.',
        products: 'The location does not have products related to the organization.',
        custom: 'Customized issue.'
      }
    },
    issuesTable: {
      headers: {
        code: 'Code',
        date: 'Date',
        type: 'Type',
        action: 'Action'
      },
      actions: {
        goToIssue: 'Go to issue'
      },
      errors: {
        noCode: 'No code',
        noIssues: 'No issues'
      }
    }
  },

  // DISPLAY COMPONENTS
  display: {
		returnPayload: 'Return to payload',
		recon: 'Products Recognition',
		imgInfo: 'Image Information',
		numTickets: 'Number of Tickets',
    imgType: 'Type:',
    imgName: 'Name:',
    loading: 'Loading result...',
    globalTags: 'Global tags: ',
    resourceTags: 'Resource tags: ',
    resultTypes: 'Result types:',
    alert: 'There is no recognition for this image.',
    resultInfo: {
      elements: 'Elements',
      predictions: 'Predictions',
      extras: 'Extras'
    },
    photosBtns: {
      previous: 'Previous Photo',
      next: 'Next Photo'
    },
    errors: {
      image: 'Error loading image'
    },
    actionBtns: {
      fullScreen: 'View image full screen',
      download: 'Download image',
      fitImg: 'Fit Image',
      createTicket: 'Create Ticket'
    },
    productsGraph: 'Products Chart'
  },
  ticketModal: {
    instuction: 'If you have not selected a result, select it before creating a ticket.',
    project: 'Project',
    name: 'Name',
    description: 'Description',
    cancel: 'Cancel',
    create: 'Create',
    creating: 'Creating...'
  },
  tagList: {
    colorSwal: {
      title: 'Transparency setting',
			text: 'Color transparency for articles',
    },
    tableHeaders: {
      color: 'Color',
      quantity: 'Quantity',
      products: 'Products',
      price: 'Price',
      score: 'Score'
    },
    errors: {
      noProducts: 'No products'
    }
  },
  tagChart: {
    errors: {
      noProducts: 'No products'
    }
  },

	// TICKETS COMPONENTS
	tickets: {
		filters: {
			projectLegend: 'Select a project.',
		},
		help: {
			title: 'Which project should I select?',
			msg: 'Projects that start with "Test -" contain tickets intended for internal testing. The other projects contain tickets that are aimed at their review and correction in a production environment.'
		}
	},
	ticketsTable: {
		errors: {
			noTickets: 'No tickets'
		}
	},

	// FRAUD
	fraud: {
		allFraudsTitle: 'Search Falsification of Information',
		alert: {
			msg: 'It was detected that this location may have a possible duplication in some of its pictures.',
			link: 'Go to report'
		},
		title: 'False Information Report',
		print: 'Print',
		false: 'Falsification',
		data: {
			image: 'Image',
			imageDate: 'Image Date',
			coordinates: 'Coordinates'
		},
		image: 'Image Similarity',
		products: 'Product Similarity',
		validationBtn: 'Start Validation Process',
		reviewMsg: 'The information has been verified by the supervisor.',
		confirm: {
			title: 'Are you sure you want to validate this location?',
			msg: 'This action will add the location to your route and cannot be undone.'
		},
		alerts: {
			success: 'The process has started, review your route in the app.',
			error: 'Error, please try again'
		},
		errorPageTitle: 'This payload does not have falsification of information.'
	},

  // UTILS COMPONENTS
  selectButton: {
    placeholder: 'Select...',
    noOptionsMessage: 'No options',
		loadingMessage: 'Loading options...'
  },
  pagination: {
		rows: 'Rows per page:',
    of: 'of'
  },
	confirmDialog: {
		confirmBtn: 'Confirm',
	},

  // STATUS DESCRIPTIONS
  statusDesc: {
    payload: {
      none: 'None',
      created: 'Created',
      waiting: 'Waiting',
      processing: 'Processing',
      good: 'Good',
      incomplete: 'Incomplete',
      bad: 'Bad',
      flagged: 'Flagged',
      in_review: 'In review',
      passed: 'Passed',
      removed: 'Removed',
      error: 'Error'
    },
    imgRecon: {
      none: 'None',
      waiting: 'Waiting',
      success: 'Success',
      error: 'Error',
      reviewed: 'Reviewed',
      unavailable: 'Unavailable'
    },
		imgType: {
			none: 'None',
			common: 'Common',
			upload: 'Upload',
			output: 'Output',
			backup: 'Backup'
		},
		imgGroup: {
			none: 'None',
			organization: 'Organization',
			module: 'Module',
			location: 'Location',
			payload: 'Payload',
			result: 'Result',
			issue: 'Issue',
			post: 'Post',
			notification: 'Notification'
		},
    location: {
      none: 'None',
      available: 'Available',
      disabled: 'Disabled',
      removed: 'Removed'
    },
    record: {
      none: 'None',
      payload: 'Payload',
      issue: 'Issue',
      retry: 'Retry',
      other: 'Other'
    },
    issue: {
      none: 'None',
      closed: 'Closed',
      closed_down: 'Closed down',
      access: 'Access',
      process: 'Process',
      products: 'Products',
      custom: 'Custom'
    },
    cycle: {
      none: 'None',
      created: 'Created',
      starting: 'Starting',
      available: 'Available',
      waiting: 'Waiting',
      completed: 'Completed',
      removed: 'Removed'
    },
		ticket: {
			none: 'None',
      created: 'Created',
      waiting: 'Waiting',
      in_review: 'In review',
			good: 'Good',
			bad: 'Bad',
			passed: 'Passed',
			error: 'Error'
		}
  }
};
