import React, { useEffect, useState } from 'react';

// Packages
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

// Components
import Loading from '../utils/Loading';
import Tag from './Tag';

// Utils
import { setColorTransparency } from '../../utils/fabricJsCustom'
import isEmpty from '../../utils/isEmpty';
import formatString from '../../utils/formatString';

// Icons
import { ReactComponent as EyeIcon } from '../../static/icons/eye.svg'
import { ReactComponent as ClosedEyeIcon } from '../../static/icons/eye-slash-fill.svg'

const TagList = (props) => {
	const { canvas, resultTagsLoading, appOrgConfig, tagsList, selectedResult, imageInfo } = props;
	
	const { t } = useTranslation();
	
	const [visibleAllTags, setVisibleAllTags] = useState(true);
	const [orgHeaders, setOrgHeaders] = useState([]);

	useEffect(() => {
		if (!isEmpty(imageInfo)) {
			// Get the organization configuration for display dashboard
			if (appOrgConfig) {
				const displayConfig = appOrgConfig?.data?.display_results?.find((result) => result.r_type_id === selectedResult.r_type_id);

				if (displayConfig) {
					setOrgHeaders(displayConfig.table_headers);
				}
				else {
					setOrgHeaders([]);
				}
			}
		}
	}, [tagsList, imageInfo, appOrgConfig]);

	const changeColorTransparency = (canvas) => {
		Swal.fire({
			title: `${t('tagList.colorSwal.title')}`,
			text: `${t('tagList.colorSwal.text')}`,
			input: 'range',
			inputValue: 0.5,
			inputAttributes: {
				min: 0,
				max: 1,
				step: 0.1
			},
		}).then((result) => {
			if (result.value && canvas){
				setColorTransparency(Number(result.value))

				canvas.getObjects().forEach((obj) => {
					if (obj.type === 'percepthorArticle'){
						obj.refreshColor()
					}
				});

				canvas.requestRenderAll()
			}
		});
	}

	if (resultTagsLoading) {
		return <Loading />
	}

	return (
		<table className='table table-striped'>
			<thead className='table-head sticky-top'>
				<tr>
					<th scope='col' className='text-center' style={{borderTop: 0, cursor: 'pointer'}}>
						{visibleAllTags 
							?	<ClosedEyeIcon onClick={() => setVisibleAllTags(!visibleAllTags)}/>
							:	<EyeIcon onClick={() => setVisibleAllTags(!visibleAllTags)}/>
						}
					</th>
					<th scope='col' className='text-center' style={{borderTop: 0, cursor: 'pointer'}}
						onClick={() => changeColorTransparency(canvas)}
					>
						{t('tagList.tableHeaders.color')}
					</th>
					<th scope='col' className='text-center' style={{borderTop: 0}}>
						{t('tagList.tableHeaders.quantity')}
					</th>
					<th scope='col' className='text-center' style={{borderTop: 0}}>{t('tagList.tableHeaders.products')}</th>
					{orgHeaders.map((header, index) => (
						<th key={index} scope='col' className='text-center' style={{borderTop: 0}}>
							{formatString(header)}
						</th>
					))}
					{/* {selectedResult.type === 3 &&
						<Fragment>
							<th scope='col' className='text-center' style={{borderTop: 0}}>{t('tagList.tableHeaders.score')}</th>
							<th scope='col' className='text-center' style={{borderTop: 0}}>{t('tagList.tableHeaders.price')}</th>
						</Fragment>
					} */}
				</tr>
			</thead>
			<tbody className={clsx({
					'table-dark': localStorage.getItem('theme') === 'dark'
				})}
			>
				{tagsList?.length > 0
					? tagsList.map((tag) => (
							<Tag
								key={tag.id.toString()}
								tagsList={tagsList}
								tag={tag}
								canvas={canvas}
								visibleAllTags={visibleAllTags}
								resultType={selectedResult.type}
								imageInfo={imageInfo}
								orgHeaders={orgHeaders}
							/>
						))
					: <tr className='align-middle text-center'>
							<td className='text-center p-2' colSpan={selectedResult.type === 3 ? '6' : '5'}>
								{t('tagList.errors.noProducts')}
							</td>
						</tr>
				}
			</tbody>
		</table>
	)
}

TagList.propTypes = {
	canvas: PropTypes.object,
	resultTagsLoading: PropTypes.bool.isRequired,
	appOrgConfig: PropTypes.object.isRequired,
	tagsList: PropTypes.array.isRequired,
	selectedResult: PropTypes.object.isRequired,
	imageInfo: PropTypes.object.isRequired
};

export default TagList;
